export default {
    black: '#000',
    white: '#fff',
    backgroundGrey: '#f0f4f8',
    buttonBackgroundGrey: '#d8dee6',
    lightGrey: '#ADADAD',
    textGrey1: '#848e95',
    textGrey2: '#808a93',
    textGrey3: '#8a8d97',
    textGrey4: '#9ca8b2',
    cloudyBlue: '#babeca',
    cloudyBlue2: '#c8d4e7',
    darkGreyFont: '#3d4b51',
    lightBlueBorder: '#e9eff4',
    charcoalGrey: '#40474c',
    blueGrey: '#848e95',
    red1: '#FE585A',
    azul: '#2d6fe5'
};
