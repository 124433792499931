export const get = async (url, params = {}) => {
    const formattedParams = Object.entries(params)
        .map(pair => pair.map(encodeURIComponent).join('='))
        .join('&')

    const finalUrl = formattedParams ? url + '?' + formattedParams : url

    try {
        const res = await fetch(finalUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return res.json()
    } catch (e) {
        return {
            error: {
                status: 'front',
                message: e,
            },
        }
    }
}
