import { useState, useEffect } from 'react';
import { useInfiniteFetch } from './useInfiniteFetch';

export const usePaginationFetch = (fetchFunction, listListenerParams) => {
    const [loadMore, setLoadMore] = useInfiniteFetch(...listListenerParams);
    const [isLoading, setIsLoading] = useState(false);
    const [storiesList, setStoriesList] = useState([]);
    const [haveMoreResult, setHaveMoreResult] = useState(true);

    const fetchWithLoader = async () => {
        if (loadMore && !isLoading) {
            setIsLoading(true);
            const response = await fetchFunction(storiesList.length);
            response.length === 0 && setHaveMoreResult(false);
            setStoriesList([...storiesList, ...response]);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (!haveMoreResult) return;
        fetchWithLoader().catch(err => console.error(err));
        setLoadMore(false);
    }, [loadMore]);
    return [storiesList, isLoading, haveMoreResult];
};
