import { useState, useEffect } from 'react'

// Our hook
export const useInfiniteFetch = (listId, securityOffset = 0, windowScroll = true) => {
    // State and setters for debounced value
    const [loadMore, setLoadMore] = useState(true)
    const handleScroll = list => () => {
        if (windowScroll) {
            if (window.scrollY + window.innerHeight >= list.clientHeight + list.offsetTop - securityOffset) {
                setLoadMore(true)
            }
        } else {
            if (list.scrollTop + list.clientHeight >= list.scrollHeight - securityOffset) {
                setLoadMore(true)
            }
        }
    }
    useEffect(() => {
        const list = window && window.document.getElementById(listId)
        if (!list) return
        const scrollParent = windowScroll ? window : list
        scrollParent.addEventListener('scroll', handleScroll(list))
        return () => scrollParent.removeEventListener('scroll', handleScroll(list))
    }, [])

    return [loadMore, setLoadMore]
}
